


$(document).ready(function(){

// PRELOADER
// $(window).on('load', function() {    
//     $(".preloader").fadeOut(500);
// });

// Navbar Class Add
$(window).scroll(function() {
    var scroll = $(window).scrollTop();
    if (scroll >= 50) {
        $(".navbar").addClass("navbarFixed");
    } else {
        $(".navbar").removeClass("navbarFixed");
    }
});





// Tooltip Int
$(function () {
  if($( window ).width() >= 767){
    $('[data-toggle="tooltip"]').tooltip({
      trigger : 'hover'
    });
    $('[data-toggle="tooltip"]').on('focus', function () {
      $(this).tooltip('hide')
    })
  }
})


/*=========Recharge Mobile and email show hide======*/
$("#emailBox").click(function(){
	$(this).hide();
	$("#cubaRecharge").hide();
	$("#emailRecharge").show();
	$("#cubacelBox").show();
})
$("#cubacelBox").click(function(){
	$(this).hide();
	$("#emailRecharge").hide();
	$("#cubaRecharge").show();
	$("#emailBox").show();
});

/*=========Payment Div show hide======*/
$("input[name='payments']").click(function() {
    var test = $(this).val();

    $(".paymentDiv").hide();
    $("#paymentOption" + test).show();
});


/*=========Pay Now recharge Loader Show hide======*/
$(".PayNowLoader").click(function(){
	$("#recLoader").show().delay(5000).fadeOut();
	setTimeout(function () {
		// swal("Recharge Done!", "Your recharge has been done successfully", "success")
		swal({
			  title: "Recharge Done!",
			  text: "Your recharge has been done successfully",
			  type: "success",
			  confirmButtonClass: "btnGradient",
			  confirmButtonText: "Ok"
		})
	}, 5300);
})



$(".clientSelect").select2();


$("input[name='recType']").click(function() {
    var test = $(this).val();
    $(".clientRecharge").hide();
    $("#clientRecOpt" + test).show();
});


$("#FamilyShow").hide();



// Date Time Picker

// $(function () {
//   $('.datepickDate').datetimepicker({
//     format: 'MM/DD/YYYY',
//     ignoreReadonly: true,
//     widgetPositioning: {
//         horizontal: 'auto',
//          vertical: 'top'
//     }
//   });
//   $('.datepickDateBottom').datetimepicker({
//     format: 'MM/DD/YYYY',
//     ignoreReadonly: true,
//     widgetPositioning: {
//         horizontal: 'auto',
//          vertical: 'bottom'
//     }
//   });
//     $('.datepickAutoPos').datetimepicker({
//     format: 'MM/DD/YYYY',
//     ignoreReadonly: true
//   });
//   $('.dateTime').datetimepicker({
//     // inline: true
//     ignoreReadonly: true
//   });  
//   $('.timeSelect').datetimepicker({
//     // inline: true
//     format: 'LT',
//     ignoreReadonly: true
//   });
// });


// $('.dataTableHistory').DataTable({
// 	"responsive": true,
// 	"ordering": false,
// 	"searching": false,
// 	"lengthChange": false
// });
// $('.dataTableSendMoney').DataTable({
//     "responsive": true,
//     "ordering": false,
//     "searching": false,
//     "lengthChange": false
// });
// $('.dataTableHistory1').DataTable({
//     "columns": [
//       null,
//       null,
//       { "width": "20%" },
//       null,
//       null,
//       null,
//       null,
//       null
//     ],
//     "responsive": true,
//     "ordering": false,
//     "searching": false,
//     "lengthChange": false
// });
// $('.dataTableHistory2').DataTable({
//     "responsive": true,
//     "ordering": false,
//     "searching": false,
//     "lengthChange": false
// });
// $('a[aria-controls="csTab2"]').on('shown.bs.tab', function (e) {
//   $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
// });


// Filter Range Slider

// $( "#ageRange" ).slider({
//       range: true,
//       min: 0,
//       max: 500,
//       values: [ 100, 350 ],
//       slide: function( event, ui ) {
//         $( "#ageValue" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
//       }
// });
// $( "#ageValue" ).val( "$" + $( "#ageRange" ).slider( "values", 0 ) +
//       " - $" + $( "#ageRange" ).slider( "values", 1 ) );



// $( "#ageRange1" ).slider({
//       range: true,
//       min: 0,
//       max: 500,
//       values: [ 100, 350 ],
//       slide: function( event, ui ) {
//         $( "#ageValue1" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
//       }
// });
// $( "#ageValue1" ).val( "$" + $( "#ageRange1" ).slider( "values", 0 ) +
//       " - $" + $( "#ageRange1" ).slider( "values", 1 ) );


// $( "#ageRange2" ).slider({
//       range: true,
//       min: 0,
//       max: 500,
//       values: [ 100, 350 ],
//       slide: function( event, ui ) {
//         $( "#ageValue2" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
//       }
// });
// $( "#ageValue2" ).val( "$" + $( "#ageRange2" ).slider( "values", 0 ) +
//       " - $" + $( "#ageRange2" ).slider( "values", 1 ) );


// $( "#ageRange3" ).slider({
//       range: true,
//       min: 0,
//       max: 500,
//       values: [ 100, 350 ],
//       slide: function( event, ui ) {
//         $( "#ageValue3" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
//       }
// });
// $( "#ageValue3" ).val( "$" + $( "#ageRange3" ).slider( "values", 0 ) +
//       " - $" + $( "#ageRange3" ).slider( "values", 1 ) );


// $( "#ageRange4" ).slider({
//       range: true,
//       min: 0,
//       max: 500,
//       values: [ 100, 350 ],
//       slide: function( event, ui ) {
//         $( "#ageValue4" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
//       }
// });
// $( "#ageValue4" ).val( "$" + $( "#ageRange4" ).slider( "values", 0 ) +
//       " - $" + $( "#ageRange4" ).slider( "values", 1 ) );

// $(".customDateIcon").click(function(){
//   $(this).parents("").find("customDate").toggle();
// });


});







function valueChanged(){
  if($('.FamilyRecharge input').is(":checked"))   {
      $("#FamilyShow").show();
  }else{
      $("#FamilyShow").hide();
  }
}

function sendMoney(){
  if($('.FamilyRecharge input').is(":checked")){
      $("#FamilyShow").show();
      $("#noFamily").hide();
  }else{
  	$("#noFamily").show();
      $("#FamilyShow").hide();
  }
}

function addFamilyDelivery(){
  if($('.FamilyRecharge1 input').is(":checked"))   {
      $(".FamilyShow1").show();
  }else{
      $(".FamilyShow1").hide();
  }
}



// Filter Sidebar toggle Function
function openNav($sliderName) {
    $('body').addClass("menuOpen");
    $('#'+$sliderName).fadeIn();
    $('.modalBackdrop').fadeIn();
}
function closeNav($sliderName) {    
    $('#'+$sliderName).fadeOut();
    $('body').removeClass("menuOpen");
    $('.modalBackdrop').fadeOut();
}


// Developer Function
var toastrSuccess = function($msg){
  toastr.remove();
  toastr.success($msg);
}

var toastrError = function($msg){
  toastr.remove();
  toastr.error($msg);
}


//Preloader hide show
var showLoader = function(){
  $('.preloader').show();
}//End

var hideLoader = function(){
  $('.preloader').hide();
}//End

// device token Genrate 

